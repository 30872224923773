import React, { useCallback, useEffect } from 'react';
import { useFetch } from 'react-async';
import { useForm } from 'react-hook-form';

import Button from '~/components/Button';
import Input from '~/components/Input';
import { CONTACT_EMAIL, CONTACT_PHONE, CONTACT_PHONE_URL } from '~/constants';
import { ValidationService } from '~/services/Validation';

import * as styles from './Contact.module.scss';

interface FormData {
  email: string;
  message: string;
}

const Contact: React.FC = () => {
  const { register, handleSubmit, formState, reset } = useForm<FormData>();
  const { isPending, error, run, data } = useFetch<{ message: string }>(
    '/api/contact',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { json: true },
  );

  const handleSubmitClick = useCallback(
    (data: FormData) => {
      run({
        body: JSON.stringify({
          email: data.email,
          text: data.message,
        }),
      });
    },
    [run],
  );

  useEffect(() => {
    reset();
  }, [data, reset]);

  const { errors } = formState;
  return (
    <section className={styles.root}>
      <div className={styles.container}>
        <h1 className={styles.title}>Kontakt</h1>

        <p className={styles.description}>
          Użyj formularza kontaktowego, napisz do mnie bezpośrednio na adres{' '}
          <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a> lub zadzwoń na numer{' '}
          <a href={`tel:${CONTACT_PHONE_URL}`}>{CONTACT_PHONE}</a>.
        </p>

        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <Input
            {...register('email', {
              required: true,
              validate: ValidationService.isValidEmail,
            })}
            className={styles.input}
            label='Twój adres email*'
            name='email'
          />
          {errors.email?.type === 'validate' && 'Podany adres nie wyglada jak adres email.'}
          {errors.email?.type === 'required' && 'Podanie adresu email jest wymagane.'}

          <Input
            {...register('message', { required: true })}
            className={styles.input}
            label='Treść wiadomości*'
            useTextarea
            name='message'
          />
          {errors.message?.type === 'required' && <div>Podanie treści wiadomości jest wymagane.</div>}

          <Button
            isLoading={isPending}
            className={styles.button}
            as={{
              tag: 'button',
              type: 'submit',
            }}
          >
            Wyślij wiadomość
          </Button>

          {data?.message && <div className={styles.message}>{data.message}</div>}
          {error?.message && <div className={styles.message}>{error.message}</div>}
        </form>
      </div>
    </section>
  );
};

export default Contact;
