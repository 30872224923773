import classNames from 'classnames';
import React, { FocusEvent, RefObject } from 'react';

import Label from '~/components/Label';

import * as styles from './Input.module.scss';

export type InputStatus = 'valid' | 'invalid';

type Props = React.InputHTMLAttributes<unknown> & {
  useTextarea?: boolean;
  status?: InputStatus;
  statusMessage?: string;
  label?: string;
};

const Input = React.forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>((props, ref) => {
  const { useTextarea, className, status, statusMessage, label, name, value, type = 'text', ...restProps } = props;
  const classes = classNames(styles.root, className, {
    [styles.textarea]: useTextarea,
    [styles[status || '']]: status,
  });

  const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (restProps.onBlur) {
      restProps.onBlur(e);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (restProps.onFocus) {
      restProps.onFocus(e);
    }
  };

  return (
    <div className={classes}>
      {label && <Label text={label} htmlFor={name} invalid={status === 'invalid'} />}
      {useTextarea ? (
        <textarea
          name={name}
          id={name}
          className={styles.field}
          ref={ref as RefObject<HTMLTextAreaElement>}
          {...restProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      ) : (
        <input
          name={name}
          id={name}
          type={type}
          className={styles.field}
          ref={ref as RefObject<HTMLInputElement>}
          {...restProps}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      )}
      {status === 'invalid' ? <p className={styles.status}>{statusMessage}</p> : null}
    </div>
  );
});

export default Input;
