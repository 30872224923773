import { isArray, isEmpty, isNumber as _isNumber, isString } from 'lodash';
import validator from 'validator';

const { isEmail, isMobilePhone } = validator;

const NOT_LETTER_REGEXP = /^[^a-zA-Z]+$/;

class ValidationService {
  validate(value: unknown, validator: (value: unknown) => boolean, errorMessage: string) {
    return validator(value) ? undefined : errorMessage;
  }

  isNotEmptyString(value: unknown) {
    return isString(value) && value.trim().length > 0;
  }

  isNotEmpty(value: unknown) {
    return !isEmpty(value);
  }

  isNumbery(value: unknown) {
    return _isNumber(value);
  }

  hasArrayAtLeastOneValue(value: unknown) {
    return isArray(value) && value.length > 0;
  }

  isDefined(value: unknown) {
    return value !== null && typeof value !== 'undefined';
  }

  isValidPhone(phone: unknown) {
    return isString(phone) && isMobilePhone(phone, 'en-US');
  }

  isGreaterThan(min: number) {
    return (value: number) => value > min;
  }

  isLessThan(max: number) {
    return (value: number) => value < max;
  }

  isLongerOrEqualThan(minLength: number) {
    return (value: unknown) => isString(value) && value.length >= minLength;
  }

  isValidEmail(email: unknown) {
    return isString(email) && isEmail(email);
  }

  isNotLetter(value: unknown) {
    return !isString(value) || NOT_LETTER_REGEXP.test(value);
  }
}

const instance = new ValidationService();
export { instance as ValidationService };
