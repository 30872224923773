import classNames from 'classnames';
import React from 'react';

import * as styles from './Label.module.scss';

type Props = React.LabelHTMLAttributes<unknown> & {
  text: string;
  invalid?: boolean;
  labelClass?: string;
};

const Label: React.FC<Props> = (props) => {
  const { labelClass, invalid, ...restProps } = props;
  const classes = classNames(styles.label, props.labelClass, {
    [styles.invalid]: invalid,
  });
  return (
    <label className={classes} {...restProps}>
      {props.text}
    </label>
  );
};
export default Label;
